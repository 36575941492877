import ButtonUnstyled from '@mui/base/ButtonUnstyled';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Typography from '@mui/material/Typography';
import { ReactNode, useEffect, useState } from 'react';
import {
  GoogleAnalyticsEventType,
  GoogleAnalyticsEventOptions,
} from 'constants/GoogleAnalytics';
import BetaButton from 'components/BetaButton';
import useMediaQuery from '@mui/material/useMediaQuery';

interface SettingsCollapseProps {
  sx?: SxProps<Theme>;
  children?: ReactNode;
  title?: string;
  initialClosed?: boolean;
  page?: string;
  sendEventToGA?: (
    event_type: GoogleAnalyticsEventType,
    data: GoogleAnalyticsEventOptions
  ) => void;
  collapsed?: (flag: boolean) => void;
  collapsible?: boolean;
}

/**
 * Collapsible container with a title.
 *
 * @param {SettingsCollapseProps} {
 *   children,
 *   sx,
 *   title,
 *   initialClosed = false,
 * }
 * @return {*}
 */
const SettingsCollapse = ({
  children,
  sx,
  title,
  page,
  initialClosed = true,
  sendEventToGA,
  collapsed,
  collapsible,
}: SettingsCollapseProps) => {
  const theme = useTheme();
  const [isCollapsed, setIsCollapsed] = useState(initialClosed);
  const toggleCollapse = () => {
    if (sendEventToGA) {
      sendEventToGA(GoogleAnalyticsEventType.SelectContent, {
        content_type: page,
        item_id: title,
      });
    }
    setIsCollapsed(!isCollapsed);
    if (collapsed) collapsed(true);
  };
  useEffect(() => {
    if (collapsible) toggleCollapse();
  }, [collapsible]);
  const isSmallScreen = useMediaQuery((mq: Theme) => mq.breakpoints.down('xs'));

  return (
    <Box
      sx={{
        ...sx,
        mb: theme.spacing(1),
        background: '#fff',
        borderRadius: '16px',
        padding: isSmallScreen ? '8px' : '16px 16px',
        border: '1px solid #DADCE0',
      }}
      data-testid="collapse-section"
    >
      <ButtonUnstyled
        component="div"
        onClick={toggleCollapse}
        data-testid="settings-collapse"
      >
        <Box
          sx={{
            display: 'flex',
            paddingTop: '0',
            paddingBottom: '0',
            justifyContent: 'space-between',
            alignItems: 'center',
            cursor: 'pointer',
            flexWrap: 'wrap',
          }}
        >
          <Typography
            sx={{
              fontWeight: '500',
              lineHeight: '1.75rem',
              fontSize: '1.3rem',
            }}
            component="h1"
          >
            {title}{' '}
            {title === 'Google Review Request' && <BetaButton></BetaButton>}
          </Typography>
          {isCollapsed ? (
            <ExpandMoreIcon aria-label={`View ${title}`} />
          ) : (
            <ExpandLessIcon aria-label={`Hide ${title}`} />
          )}
        </Box>
      </ButtonUnstyled>

      <Collapse in={!isCollapsed}>
        <Box sx={{ paddingTop: '1rem', paddingBottom: '1rem' }}>{children}</Box>
      </Collapse>
    </Box>
  );
};
export default SettingsCollapse;
