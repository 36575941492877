import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MuiTooltip, { TooltipProps } from '@mui/material/Tooltip';
import { useTheme, styled } from '@mui/material/styles';

interface TitleProps {
  title: string | React.ReactFragment | React.ReactChild | React.ReactPortal;
  id?: string;
}
const ToBeStyledTooltip = ({ className, ...props }: TooltipProps) => (
  <MuiTooltip
    {...props}
    arrow
    classes={{ tooltip: className }}
    tabIndex={0}
    role="tooltip"
  />
);

/**
 *
 * Tooltip with default styles.
 *
 * @type {*} */
export const DefaultToolTip = styled(ToBeStyledTooltip)(({ theme }) => ({
  color: theme.namedColors.brand.copy,
  backgroundColor: theme.namedColors.utility.white,
  boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1)',
  borderRadius: '8px',
  '& .MuiTooltip-arrow': {
    color: theme.namedColors.utility.white,
  },
}));

/**
 * Generic info tool tip with a provided info Icon.
 *
 * @param {TitleProps} { title }
 * @return {*}
 */
export const InfoTooltip = ({ title, id }: TitleProps) => {
  const theme = useTheme();
  return (
    <DefaultToolTip title={title} data-testid="tooltip" id={id}>
      <InfoOutlinedIcon
        sx={{
          height: '16px',
          width: '16px',
          color: theme.namedColors?.dark[300],
        }}
        tabIndex={0}
        aria-describedby={id}
      />
    </DefaultToolTip>
  );
};

export const UtilityToolTip = styled(DefaultToolTip)(() => ({
  padding: '.75rem',
  fontSize: '.75rem',
}));

/**
 * Tool tip for successful context
 * @type {*} */
export const SuccessToolTip = styled(UtilityToolTip)(({ theme }) => ({
  color: theme.namedColors.utility.white,
  backgroundColor: theme.namedColors.utility.success,
  '& .MuiTooltip-arrow': {
    color: theme.namedColors.utility.success,
  },
}));

/**
 * Tool tip for successful context
 * @type {*} */
export const ErrorToolTip = styled(UtilityToolTip)(({ theme }) => ({
  color: theme.namedColors.utility.white,
  backgroundColor: theme.namedColors.utility.critical,
  '& .MuiTooltip-arrow': {
    color: theme.namedColors.utility.critical,
  },
}));

/**
 * Tool tip for successful context
 * @type {*} */
export const HighlightToolTip = styled(UtilityToolTip)(({ theme }) => ({
  color: theme.namedColors.utility.white,
  backgroundColor: theme.namedColors.utility.highlight,
  '& .MuiTooltip-arrow': {
    color: theme.namedColors.utility.highlight,
  },
}));
