import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useAgentIdHistory } from 'hooks/useAgentIdHistory';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled, Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
interface BoBSelectorProps {
  onAgentSelected: (id: string) => void;
  validAgentIds: string[];
  defaultAgentId: string;
}

const AgentCodeLabel = styled(Box)(({ theme }) => ({
  border: '1px solid #888888',
  borderRadius: '20px',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '3px 12px',
  backgroundColor: theme.namedColors.utility.white,
}));

const AgentDropDown = ({
  onAgentSelected,
  validAgentIds,
  defaultAgentId,
}: BoBSelectorProps) => {
  const [selectedBoB, setSelectedBoB] = useState(defaultAgentId);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [agentIdHistory, setAgentIdHistory] = useAgentIdHistory();
  const handleBoBChange = (event: SelectChangeEvent) => {
    const clientId = event.target.value;
    setSelectedBoB(clientId);
    onAgentSelected(clientId);
    setAgentIdHistory([clientId]);
  };
  const theme = useTheme();
  const isSmallScreen = useMediaQuery((mq: Theme) => mq.breakpoints.down('xs'));

  return (
    <FormControl
      fullWidth
      sx={{
        width: isSmallScreen ? '115px' : '180px',
        alignSelf: 'flex-end',
      }}
    >
      {validAgentIds.length === 1 ? (
        <AgentCodeLabel id="bob-label">
          <Typography
            component="h5"
            variant="h6"
            sx={{
              color: theme.namedColors.utility.black,
              fontSize: '12px',
            }}
          >
            Agent Office Code <br />
            {selectedBoB}
          </Typography>
        </AgentCodeLabel>
      ) : (
        <>
          <InputLabel id="bob-select-label">Agent Office Code</InputLabel>
          <Select
            label="Agent Office Code"
            data-testid="bob-select"
            id="bob-select"
            value={selectedBoB}
            onChange={handleBoBChange}
          >
            {validAgentIds.map((id) => (
              <MenuItem key={id} value={id}>
                {id}
              </MenuItem>
            ))}
          </Select>
        </>
      )}
    </FormControl>
  );
};

export { AgentDropDown };
