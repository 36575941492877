import { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  AntSwitch,
  FormLabelStyled,
  SettingBoxStyled,
  SettingsContent,
  SettingsSubTitle,
} from 'constants/SettingsStyled';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { SettingsCollapse } from '../../../../components/SettingsCollapse';
import {
  GoogleAnalyticsEventOptions,
  GoogleAnalyticsEventType,
} from 'constants/GoogleAnalytics';
import { GoogleReviewRequest, UserSettingsData } from '@types';
import { InputAdornment, TextField, Typography } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';

interface GoogleReviewProps {
  sendEventToGA?: (
    event_type: GoogleAnalyticsEventType,
    data: GoogleAnalyticsEventOptions
  ) => void;
  initialValue: GoogleReviewRequest;
  onSaveUserSetting: (data: UserSettingsData) => void;
  onSettingChanged: (
    type: string,
    name: string,
    valid?: boolean,
    data?: UserSettingsData
  ) => void;
  collapsed: (value: boolean) => void;
  collapsible?: boolean;
}

interface ToggleValue {
  isEnabled: boolean;
}

const GoogleReview = ({
  sendEventToGA,
  initialValue,
  onSaveUserSetting,
  onSettingChanged,
  collapsed,
  collapsible,
}: GoogleReviewProps) => {
  const theme = useTheme();

  const [businessPageLink, setBusinessPageLink] = useState<string>(
    initialValue.businessPageLink
  );
  const [state, setState] = useState<ToggleValue>({
    isEnabled: initialValue.isEnabled,
  });

  const [errorMessage, setErrorMessage] = useState<string>('');

  const gaEvents = (item_id: string) => {
    if (sendEventToGA)
      sendEventToGA(GoogleAnalyticsEventType.SelectContent, {
        content_type: 'Google Review Request',
        item_id: item_id,
      });
  };
  const onChangeEnabled = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
    if (!event.target.checked) {
      setBusinessPageLink('');
      setErrorMessage('');
    }
    gaEvents(
      event.target.checked
        ? 'Google Review Request Enabled'
        : 'Google Review Request Disabled'
    );
    onSaveUserSetting({
      googleReviewRequest: {
        isEnabled: event.target.checked,
        businessPageLink: !event.target.checked ? '' : businessPageLink,
      },
    });
  };

  const onSaveUrl = () => {
    if (initialValue.businessPageLink == businessPageLink) return;
    if (!urlPatternValidation(businessPageLink)) {
      setErrorMessage(
        'Sorry, that URL is invalid. Check the link and try again.'
      );
    } else {
      setErrorMessage('');
      onSaveUserSetting({
        googleReviewRequest: {
          isEnabled: state.isEnabled,
          businessPageLink: businessPageLink,
        },
      });
      gaEvents(
        businessPageLink
          ? 'Google Bussiness page url added'
          : 'Google Bussiness page url removed'
      );
    }
  };

  const fieldColor = errorMessage
    ? theme.namedColors.utility.caution
    : 'inherit';

  const urlPatternValidation = (url: string) => {
    const regex = new RegExp(
      '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'
    );
    return regex.test(url);
  };

  const onChangeBussinessInput = (value: string) => {
    setBusinessPageLink(value);
    const validUrl = urlPatternValidation(value);
    if (!validUrl) {
      onSettingChanged('add', 'googleReviewRequest', validUrl, {
        googleReviewRequest: {
          isEnabled: state.isEnabled,
          businessPageLink: value,
        },
      });
      setErrorMessage(
        'Sorry, that URL is invalid. Check the link and try again.'
      );
    } else setErrorMessage('');
  };

  return (
    <SettingsCollapse
      data-testid="settings-collapse-google-review"
      key="settings-collapse-google-review"
      title="Google Review Request"
      page="User Settings"
      sendEventToGA={sendEventToGA}
      collapsed={collapsed}
      collapsible={collapsible}
    >
      <SettingsContent
        sx={{
          mb: theme.spacing(2),
          wordBreak: 'break-word',
        }}
      >
        Request Google reviews from customers who said they are highly likely to
        recommend your business to friends and colleagues.
      </SettingsContent>

      <Box>
        <Box
          sx={{
            mb: theme.spacing(1),
          }}
        >
          <FormControl component="fieldset" variant="standard">
            <FormGroup>
              <FormControlLabel
                sx={{ margin: '0px', flexWrap: 'wrap' }}
                control={
                  <AntSwitch
                    checked={state.isEnabled}
                    onChange={onChangeEnabled}
                    name="isEnabled"
                    data-testid="isEnabledGR"
                  />
                }
                label="Enable Google review requests"
              />
            </FormGroup>
          </FormControl>
        </Box>
        <Box
          sx={{
            pointerEvents: state.isEnabled ? 'all' : 'none',
            opacity: state.isEnabled ? '1' : '0.5',
          }}
        >
          <SettingBoxStyled>
            <SettingsSubTitle>Link to Google Business Page</SettingsSubTitle>
            <FormControl component="fieldset" variant="standard">
              <FormGroup>
                <FormLabelStyled>
                  Provide a link to your Google business page so customers can
                  leave a review.
                </FormLabelStyled>
                <FormControl
                  sx={{ width: 'auto', mt: 2, mb: 1, marginTop: '10px' }}
                >
                  <TextField
                    data-testid="bussineslink-field"
                    sx={{
                      'label.Mui-focused': {
                        color: fieldColor,
                      },
                      '& .Mui-focused fieldset': {
                        borderColor: `${fieldColor} !important`,
                      },
                      position: 'relative',
                    }}
                    size="small"
                    variant="outlined"
                    label="Your Google business page URL"
                    autoComplete="off"
                    value={businessPageLink}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      onChangeBussinessInput(event.target.value);
                    }}
                    onBlur={onSaveUrl}
                    InputProps={{
                      endAdornment: businessPageLink &&
                        !urlPatternValidation(businessPageLink) && (
                          <InputAdornment position="end">
                            <ErrorOutline color="error" />
                          </InputAdornment>
                        ),
                    }}
                  />
                  <Typography
                    color="error"
                    fontSize="0.75rem"
                    sx={{
                      fontSize: '0.75rem',
                      position: 'absolute',
                      bottom: '-22px',
                      margin: '4px 16px 0',
                      width: '100%',
                    }}
                  >
                    {errorMessage}
                  </Typography>
                </FormControl>
              </FormGroup>
            </FormControl>
          </SettingBoxStyled>
        </Box>
        <Box sx={{ wordBreak: 'break-word' }}>
          <Typography sx={{ margin: '15px 0 10px' }}>
            Follow these steps to get your link
          </Typography>
          <ol style={{ paddingLeft: '24px' }}>
            <li>
              Go to your Business Profile.{' '}
              <a
                href="https://support.google.com/business/answer/145585"
                target="_blank"
                style={{ textDecoration: 'underline' }}
              >
                {' '}
                Learn how to find your profile
              </a>
              .
            </li>
            <li>To find your review link:</li>
            <ol type="a" style={{ paddingLeft: '24px' }}>
              <li>
                With Google Search, <b>select Ask for reviews</b>
              </li>
              <li>
                With Google Maps, select{' '}
                <b>{`Customers > Reviews > Get more reviews`}</b>
              </li>
            </ol>
            <li>Copy your link using the sharing options provided.</li>
          </ol>
        </Box>
      </Box>
    </SettingsCollapse>
  );
};
export default GoogleReview;
