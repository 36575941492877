import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import googleIcon from './assets/google_logo.png';
import BetaButton from '../../../../components/BetaButton';
import { SMSPopupData } from '@types';

export interface GoogleReviewRequestProps {
  surveyData: {
    firstName: string;
    lastName: string;
    callbackNumber: string;
    location: string;
    clientName: string;
    jobId: number;
    googleReviewRequestStatus: null | 'ReviewRequested' | 'ReviewNotInitiated';
    showBetaNotification?: boolean;
  };
  showGoogleReviewRequest: (data: SMSPopupData) => void;
  sendRequestFor: number;
}

const GoogleReviewRequest = ({
  surveyData,
  showGoogleReviewRequest,
  sendRequestFor,
}: GoogleReviewRequestProps) => {
  const {
    firstName,
    lastName,
    location,
    callbackNumber,
    clientName,
    jobId,
    googleReviewRequestStatus,
    showBetaNotification,
  } = surveyData;

  const [sendStatus, setSendStatus] = useState<boolean>(false);
  const [showBetaPop, setShowBetaPop] = useState<boolean | undefined>(
    showBetaNotification
  );

  useEffect(() => {
    if (sendRequestFor == jobId) setSendStatus(true);
  }, [sendRequestFor]);

  const preCheckGoogleReview = () => {
    showGoogleReviewRequest({
      callbackNumber: callbackNumber,
      firstName: firstName,
      lastName: lastName,
      customerLocation: location,
      clientName: clientName,
      jobId: jobId,
    });
  };
  const onBetaDismiss = () => {
    setShowBetaPop(false);
  };

  return (
    <>
      <Typography
        component="div"
        sx={(theme) => ({ mb: theme.spacing(2), position: 'relative' })}
      >
        <Button
          data-testid="google-request-button"
          onClick={preCheckGoogleReview}
          sx={(theme) => ({
            border: '1px solid ' + theme.namedColors.secondary.default,
            padding: '5px 10px',
            color: theme.namedColors.dark[900],
            background: theme.namedColors.utility.white,
            lineHeight: '20px',
            '&.Mui-disabled': {
              background: 'rgba(0, 0, 0, 0.26)',
              color: theme.namedColors.dark[900],
            },
            '&& .MuiTouchRipple-rippleVisible': {
              opacity: 0.5,
            },
            '&& .MuiTouchRipple-child': {
              color: '#000000dd',
            },
          })}
          disabled={
            googleReviewRequestStatus === 'ReviewRequested' || sendStatus
          }
        >
          <img
            src={googleIcon}
            style={{ width: '18px', height: '18px', marginRight: '5px' }}
          />
          {googleReviewRequestStatus === 'ReviewRequested' || sendStatus
            ? 'Google Review Requested'
            : 'Request a Google Review'}
        </Button>
        <BetaButton></BetaButton>
        {showBetaPop && (
          <Typography
            component="div"
            sx={(theme) => ({
              mb: theme.spacing(2),
              position: 'absolute',
              top: '43px',
              left: '10px',
              background: '#fff',
              boxShadow: '-1px 1px 9px 5px #ddd',
              padding: '15px 20px',
              border: '1px solid #ddd',
              zIndex: '2',
              width: '70%',
            })}
          >
            <Typography
              component="p"
              sx={(theme) => ({ fontWeight: 500, mb: theme.spacing(2) })}
              data-testid="beta-notification-head"
            >
              Request a Google Review <BetaButton></BetaButton>
            </Typography>
            <CloseIcon
              data-testid="beta-cancelIcon"
              sx={{
                position: 'absolute',
                right: '10px',
                top: '14px',
                cursor: 'pointer',
                fontSize: '1.3rem',
              }}
              onClick={onBetaDismiss}
            />
            <p>
              We’re giving you a sneak peek of this new feature! You can now
              request a Google review via SMS text for your business from
              customers who gave you a score of 9 or 10 when asked their
              likelihood to recommend to friends or colleagues (on a scale of
              0-10).
            </p>
            <p>
              Ready to take control of your online reputation? Click{' '}
              <a
                href="https://info.agero.com/roadside-google-reviews"
                target="_blank"
                style={{ textDecoration: 'underline' }}
              >
                here
              </a>{' '}
              to learn more.
            </p>
          </Typography>
        )}
      </Typography>
    </>
  );
};

export default GoogleReviewRequest;
