import { Button } from '@mui/material';

const BetaButton = () => {
  return (
    <Button
      sx={(theme) => ({
        padding: '5px 10px',
        color: theme.namedColors.brand.copy,
        background: '#ddd',
        borderRadius: '4px',
        ml: theme.spacing(1),
        lineHeight: '20px',
        '&:hover': {
          background: '#ddd',
        },
        '&& .MuiTouchRipple-rippleVisible': {
          opacity: 0.5,
        },
        '&& .MuiTouchRipple-child': {
          color: '#000000dd',
        },
        pointerEvents: 'none',
      })}
    >
      BETA
    </Button>
  );
};
export default BetaButton;
