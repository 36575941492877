import Typography from '@mui/material/Typography';
import { NpsMetricsPerformance } from '@types';
import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import infoIcon from './assets/info.svg';
import { DefaultToolTip as Tooltip } from 'components/Tooltips';
import {
  GoogleAnalyticsEventType,
  GoogleAnalyticsEventOptions,
} from 'constants/GoogleAnalytics';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme } from '@mui/material/styles';

const ScoreChip = styled(Box)(({ theme }) => ({
  borderRadius: '16px',
  padding: '12px',
  border: `1px solid ${theme.namedColors.light[900]}`,
  height: '100%',
}));

const ScoreChipTitle = styled(Box)(({}) => ({
  fontSize: '12px',
  marginBottom: '10px',
}));
const ScoreChipValue = styled(Box)(({}) => ({
  fontWeight: '500',
  fontSize: '20px',
  display: 'inline-flex',
  flexWrap: 'wrap',
  columnGap: '15px',
}));
const ScoreChipState = styled(Box)(({}) => ({
  position: 'relative',
  paddingLeft: '23px',
  fontSize: '16px',
  fontWeight: 'normal',
}));

const VsPercent = styled('span')(({}) => ({
  fontSize: '20px',
  fontWeight: 500,
}));

const getArrow = (value: number) => {
  if (value < 0) {
    return (
      <ArrowDownwardIcon
        sx={{ position: 'absolute', left: '0px', top: '3px' }}
      />
    );
  } else if (value > 0)
    return (
      <ArrowUpwardIcon sx={{ position: 'absolute', left: '0px', top: '3px' }} />
    );
  else return '';
};

const NpsPerformanceCard = ({
  metricData,
  enableStateLevelNps,
  showPerformanceBreakDown,
  sendEventToGA,
}: {
  metricData: NpsMetricsPerformance;
  enableStateLevelNps: boolean;
  showPerformanceBreakDown: (flag: boolean) => void;
  sendEventToGA?: (
    event_type: GoogleAnalyticsEventType,
    data: GoogleAnalyticsEventOptions
  ) => void;
}) => {
  const isMobile = useMediaQuery((mq: Theme) => mq.breakpoints.down('md'));
  const isSmallScreen = useMediaQuery((mq: Theme) => mq.breakpoints.down('xs'));
  const onViewBreakdown = () => {
    if (sendEventToGA) {
      sendEventToGA(GoogleAnalyticsEventType.SelectContent, {
        content_type: 'Performance Metrics',
        item_id: 'View Performance Breakdown',
      });
    }
    showPerformanceBreakDown(true);
  };

  return (
    <Box
      sx={{
        flex: '1.3',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginBottom: '24px',
        gap: '10px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          minHeight: isMobile ? 'inherit' : '33.33%',
          gap: '10px',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            flex: '1',
            gap: '10px',
            justifyContent: 'space-between',
            flexWrap: isSmallScreen ? 'wrap' : 'nowrap',
            alignContent: 'stretch',
          }}
        >
          <ScoreChip sx={{ flex: 1, height: 'auto' }}>
            <ScoreChipTitle>IN PROGRESS EVENTS</ScoreChipTitle>
            <ScoreChipValue>{metricData.eventsInProgress}</ScoreChipValue>
          </ScoreChip>
          <ScoreChip sx={{ flex: 1, height: 'auto' }}>
            <ScoreChipTitle>TOTAL EVENTS</ScoreChipTitle>
            <ScoreChipValue>{metricData.eventsTotal}</ScoreChipValue>
          </ScoreChip>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'column nowrap',
            flex: '1',
            minHeight: isMobile ? 'inherit' : '33.33%',
          }}
        >
          <ScoreChip sx={{ height: '100%' }}>
            <ScoreChipTitle>COMPLAINT RATE</ScoreChipTitle>
            <ScoreChipValue>
              {metricData.complaintRate}%
              {enableStateLevelNps && (
                <ScoreChipState>
                  <VsPercent
                    sx={(theme) => ({
                      color:
                        metricData.vsState.complaintRate > 0
                          ? theme.namedColors.primary.default
                          : theme.namedColors.performance.text.good,
                    })}
                  >
                    {getArrow(metricData.vsState.complaintRate)}
                    {metricData.vsState.complaintRate < 0
                      ? -1 * metricData.vsState.complaintRate
                      : metricData.vsState.complaintRate}
                    %{' '}
                  </VsPercent>
                  vs State
                </ScoreChipState>
              )}
            </ScoreChipValue>
          </ScoreChip>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          minHeight: isMobile ? 'inherit' : '33.33%',
          gap: '10px',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <Box sx={{ display: 'flex', flexFlow: 'column wrap', flex: '1 1 0' }}>
          <ScoreChip>
            <ScoreChipTitle>
              AVERAGE ARRIVAL TIME
              <Tooltip
                placement="right"
                title={
                  <Typography>
                    <strong>Average Arrival Time</strong> (ATA) is the average
                    amount of time between delivery of an ETA to the customer
                    and when the service provider arrives at the scene, as
                    measured by the provider's live event tracking technology or
                    by confirmation from the customer.
                  </Typography>
                }
              >
                <img
                  src={infoIcon}
                  style={{ margin: '-2px 0 0 4px' }}
                  alt="What is ATA?"
                />
              </Tooltip>
            </ScoreChipTitle>
            <ScoreChipValue>
              {metricData.averageArrivalTime}min
              {enableStateLevelNps && (
                <ScoreChipState>
                  <VsPercent
                    sx={(theme) => ({
                      color:
                        metricData.vsState.averageArrivalTime > 0
                          ? theme.namedColors.primary.default
                          : theme.namedColors.performance.text.good,
                    })}
                  >
                    {getArrow(metricData.vsState.averageArrivalTime)}
                    {metricData.vsState.averageArrivalTime < 0
                      ? -1 * metricData.vsState.averageArrivalTime
                      : metricData.vsState.averageArrivalTime}
                    %{' '}
                  </VsPercent>
                  vs State
                </ScoreChipState>
              )}
            </ScoreChipValue>
          </ScoreChip>
        </Box>
        <Box sx={{ display: 'flex', flexFlow: 'column wrap', flex: '1 1 0' }}>
          <ScoreChip>
            <ScoreChipTitle>
              DIGITAL APP REQUEST
              <Tooltip
                placement="right"
                title={
                  <Typography>
                    <strong>Digital App Request</strong> is the percent of
                    service requests made through the client's digital app or
                    website instead of by phone. For more information on this,
                    visit the Roadside Guide section.
                  </Typography>
                }
              >
                <img
                  src={infoIcon}
                  style={{ margin: '-2px 0 0 4px' }}
                  alt="What is DAR?"
                />
              </Tooltip>
            </ScoreChipTitle>
            <ScoreChipValue>
              {metricData.appEngagement}%
              {enableStateLevelNps && (
                <ScoreChipState>
                  <VsPercent
                    sx={(theme) => ({
                      color:
                        metricData.vsState.appEngagement < 0
                          ? theme.namedColors.primary.default
                          : theme.namedColors.performance.text.good,
                    })}
                  >
                    {getArrow(metricData.vsState.appEngagement)}
                    {metricData.vsState.appEngagement < 0
                      ? -1 * metricData.vsState.appEngagement
                      : metricData.vsState.appEngagement}
                    %{' '}
                  </VsPercent>
                  vs State
                </ScoreChipState>
              )}
            </ScoreChipValue>
          </ScoreChip>
        </Box>
      </Box>
      <Box
        sx={{
          minHeight: isMobile ? 'inherit' : '33.33%',
        }}
      >
        <ScoreChip sx={{ paddingTop: '5px' }}>
          <Typography
            component="div"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              flexWrap: isMobile ? 'wrap' : 'nowrap',
            }}
          >
            <Typography
              component="span"
              sx={{
                paddingRight: '10px',
              }}
            >
              Dive deeper into the factors driving your Emergency Roadside
              performance
            </Typography>
            <Typography
              component="div"
              sx={{ whiteSpace: isMobile ? 'wrap' : 'nowrap' }}
            >
              <Button
                sx={(theme) => ({
                  background: theme.namedColors.client,
                  color: theme.namedColors.utility.white,
                  mt: '5px',
                  padding: '7px 15px',
                  '&:hover': {
                    background: theme.namedColors.client,
                    opacity: 0.8,
                  },
                  '&.Mui-focusVisible': {
                    backgroundColor: `${theme.namedColors.client}`,
                    border: '2px solid #000',
                  },
                })}
                onClick={onViewBreakdown}
                data-testid="view-breakdown"
                disableRipple
              >
                View Performance Breakdown
              </Button>
            </Typography>
          </Typography>
        </ScoreChip>
      </Box>
    </Box>
  );
};
export default NpsPerformanceCard;
