import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TimeSeries } from '@types';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';

export interface TimeSeriesTableProps {
  data: TimeSeries;
  valueType: string;
  enableStateLevelNps: boolean;
}

const formatDate = (date: Date) => {
  return date.toLocaleString('default', {
    month: 'long',
  });
};

export default function TimeSeriesTable(props: TimeSeriesTableProps) {
  const isMobile = useMediaQuery((mq: Theme) => mq.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        display: 'table',
        tableLayout: 'fixed',
        width: isMobile ? '100%' : '450px',
        marginTop: '10px',
      }}
    >
      <TableContainer
        component={Paper}
        sx={{
          border: '1px solid #B1B1B1',
          boxShadow: 'none',
          display: 'table-cell',
          overflowX: 'auto',
          width: '100%',
        }}
      >
        <Table
          sx={{
            minWidth: isMobile ? 450 : '100%',
            '& th , td': {
              textAlign: 'center',
              borderRight: '1px solid rgba(224, 224, 224, 1)',
            },
            '& th:first-child , & td:first-child ': { textAlign: 'left' },
            '& th:last-child , & td:last-child ': { borderRight: 0 },
          }}
          aria-label="time series table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Month</TableCell>
              <TableCell>Your Policyholder ({props.valueType})</TableCell>
              {props.enableStateLevelNps && (
                <TableCell>Your State ({props.valueType})</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((row) => (
              <TableRow key={row.date}>
                <TableCell component="td" scope="row">
                  {formatDate(row.date as Date)}
                </TableCell>
                <TableCell component="td" scope="row">
                  {row.count}
                </TableCell>
                {props.enableStateLevelNps && (
                  <TableCell component="td" scope="row">
                    {row.stateCount}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
