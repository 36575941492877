import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { LoginForm } from 'components/LoginForm';
import { useAuthentication } from 'hooks/useAuthentication';
import { useConfig } from 'hooks/useConfig';

import logo from 'assets/images/logo.png';
import { useLogger } from 'api/logger';
import { Log } from '@types';
import { LogAction, LogLevel } from 'constants/Logger';
import { useLoadScript } from 'hooks/useLoadScript';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import {
  GoogleAnalyticsContentGroup,
  GoogleAnalyticsEventType,
  GoogleAnalyticsPageLocation,
  GoogleAnalyticsPageTitle,
} from 'constants/GoogleAnalytics';

/**
 * Login Screen
 *
 * @return {*}
 */
const Login = () => {
  const { auth } = useAuthentication();
  const { app } = useConfig();
  const { sendGAEventLogin } = useGoogleAnalytics();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const inactiveStorageKey = 'agent-inactive';
  const [logData, setLogData] = useState<Log | null>(null);
  const { refetch: refetchLogger } = useLogger(logData);
  useLoadScript();

  useEffect(() => {
    if (logData) refetchLogger();
  }, [logData]);

  useEffect(() => {
    document.title = 'Login | Roadside AID';
    if (localStorage[inactiveStorageKey]) {
      const userInfo = JSON.parse(localStorage[inactiveStorageKey]);
      setErrorMessage(
        'For security reasons, you have been logged out due to inactivity.'
      );
      setLogData({
        logAction: LogAction.LOGOUT,
        logLevel: LogLevel.WARN,
        logMessage: 'User logged out due to inactivity',
        logDescription: 'User logged out due to inactivity',
        user: userInfo.user,
        corelationId: userInfo.corelationId,
      });
      localStorage.removeItem(inactiveStorageKey);
    }
    sendGAEventLogin(GoogleAnalyticsEventType.PageView, {
      content_group: GoogleAnalyticsContentGroup.Login,
      page_location: GoogleAnalyticsPageLocation.Login,
      page_title: GoogleAnalyticsPageTitle.Login,
    });
  }, []);

  const handleSubmit = (email: string) => {
    setIsLoading(true);
    setErrorMessage('');
    auth.getProvider(
      app.enableDomainSwap ? auth.convertAuthEmail(email) : email,
      (err, provider) => {
        if (err) {
          setIsLoading(false);
          setErrorMessage(
            `The email address you entered isn’t associated with any account. Please use a valid email or contact your organization's support team.`
          );
          setLogData({
            logAction: LogAction.LOGIN,
            logLevel: LogLevel.ERROR,
            logMessage: 'Login Failed - Invalid Username Entered',
            logDescription: 'Error retrieving authentication provider',
            user: {
              email: email,
              officeCode: null,
              clientCode: null,
            },
            additionalInfo: JSON.stringify(err),
          });
          return;
        }
        // Redirects user to login screen
        auth.getSession(provider.provider_name);
      }
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
      data-testid="login"
      role="main"
    >
      <Box
        component="img"
        src={logo}
        sx={{ maxWidth: '400px', width: '100%', my: '2.5rem', px: '1rem' }}
        alt="RoadSide AID"
      ></Box>
      <Box
        sx={{
          maxWidth: 600,
          mx: 2,
        }}
      >
        <LoginForm
          isLoading={isLoading}
          onSubmit={handleSubmit}
          errorMessage={errorMessage}
        ></LoginForm>
      </Box>
    </Box>
  );
};

export default Login;
