import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { scaleLinear, scaleBand } from 'd3-scale';
import { BarChartData } from '@types';
import useMediaQuery from '@mui/material/useMediaQuery';

interface BarChartProps {
  maxValue: number;
  data: BarChartData;
  isBidirectional: boolean;
}

const BarChart = (props: BarChartProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery((mq: Theme) => mq.breakpoints.down('sm'));
  const { data, maxValue, isBidirectional } = props;
  const width = 400;
  const height = 225;
  const yAxisLabelBuffer = width / 4;
  const valueLabelBuffer = width / 9;
  const maximumBarLength = width - yAxisLabelBuffer - valueLabelBuffer;
  const yAxisPositionX = 0 + yAxisLabelBuffer;
  const midPoint = (width - yAxisLabelBuffer) / 2 + yAxisLabelBuffer;
  const paddingApplied = 10;
  const graphStartPoint = midPoint + paddingApplied;
  const fullWidth = width + 20;

  const xScale = scaleLinear()
    .domain([0, maxValue || 1])
    .range([0, maximumBarLength]);
  const yScale = scaleBand()
    .domain(data.map(({ label }) => label))
    .range([0, height])
    .paddingInner(0.28);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderGraph = (item: any) => {
    const subgroup = 'value';
    const subGroupScale = scaleBand()
      .domain(['value'])
      .range([0, yScale.bandwidth()])
      .paddingInner(0.25)
      .align(0.5);
    const value = item[subgroup];
    const valueLabel = item.valueLabel ? item.valueLabel : `${value}%`;
    const opacity = item.opacity ? item.opacity : 1;
    const barHeight = subGroupScale.bandwidth();
    const xScaleValue = xScale(value);
    const yScaleBand = subGroupScale(subgroup) || 0;
    const yAxisLabelPosition = {
      y: yScaleBand + 0.5 * barHeight,
      x: yAxisPositionX - 10,
    };
    const barPosition = {
      x: isBidirectional
        ? xScale(Math.min(0, value)) / 2 + graphStartPoint
        : yAxisPositionX,
      width: isBidirectional ? Math.abs(xScaleValue) / 2 : xScaleValue,
      y: yScaleBand,
    };
    const valueLabelPosition = {
      y: yScaleBand + 0.5 * barHeight,
      x: isBidirectional
        ? (value < 0 ? xScaleValue - 10 : xScaleValue + 10) / 2 +
          graphStartPoint
        : yAxisPositionX + xScaleValue + (value !== 0 ? 10 : 0),
    };
    return (
      <g key={`${item.label}-${subgroup}`}>
        <text
          x={yAxisLabelPosition.x}
          y={yAxisLabelPosition.y}
          textAnchor="end"
          alignmentBaseline="middle"
          fontSize="0.75em"
          fill={theme.namedColors.dark[500]}
          fontWeight="500"
        >
          {item.label}
        </text>
        <rect
          x={barPosition.x}
          width={barPosition.width}
          y={barPosition.y}
          height={barHeight}
          fill={item.color}
          fillOpacity={opacity}
        />
        <text
          x={valueLabelPosition.x}
          y={valueLabelPosition.y}
          textAnchor={value < 0 ? 'end' : 'start'}
          alignmentBaseline="middle"
          fontSize="0.825em"
          fill={theme.namedColors.dark[900]}
          fontWeight={500}
        >
          {valueLabel}
        </text>
      </g>
    );
  };

  return (
    <>
      <Box
        sx={{
          minWidth: isMobile ? '100%' : fullWidth,
        }}
      >
        <svg
          viewBox={[0, 0, fullWidth, height].join(', ')}
          width="100%"
          height="100%"
        >
          {isBidirectional && (
            <line
              x1={graphStartPoint}
              y1="0"
              x2={graphStartPoint}
              y2={height}
              stroke="#B1B1B1"
              strokeWidth={1}
            />
          )}
          (
          {data.map((d) => (
            <g key={d.label} transform={`translate(0, ${yScale(d.label)})`}>
              {renderGraph(d)}
            </g>
          ))}
          )
        </svg>
      </Box>
    </>
  );
};

export default BarChart;
