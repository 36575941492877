import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MetricCard from '../MetricCard';
import TimeSeriesGraph from '../TimeSeriesGraph';
import { TimeSeriesGraphProps } from '../TimeSeriesGraph/TimeSeriesGraph';
import Button from '@mui/material/Button';
import { useState } from 'react';
import TimeSeriesTable from 'views/Dashboard/components/TimeSeriesTable';

interface TimeSeriesCardProps extends TimeSeriesGraphProps {
  title: string;
  loading: boolean;
  valueType: string;
}

const TimeSeriesCard = (props: TimeSeriesCardProps) => {
  const { loading, title, ...graphProps } = props;
  const theme = useTheme();
  const [showGraph, setShowGraph] = useState<boolean>(true);
  return (
    <MetricCard
      subtitleStyle={{ display: 'none' }}
      loading={loading}
      title={title}
      noBorder
    >
      <Box>
        {showGraph && <TimeSeriesGraph {...graphProps} />}
        {!showGraph && (
          <TimeSeriesTable
            data={graphProps.data}
            valueType={graphProps.valueType}
            enableStateLevelNps={props.enableStateLevelNps}
          />
        )}
        <Typography
          sx={{
            color: theme.namedColors.dark[500],
            fontWeight: 400,
            fontSize: '0.75rem',
            mt: '10px',
          }}
        >
          <span style={{ padding: '3px 5px' }}>
            *Data defaulted to 90 days for trending accuracy.
          </span>
          <Button
            onClick={() => setShowGraph(!showGraph)}
            sx={{
              fontSize: '0.75rem',
              '&:hover': {
                background: 'none',
              },
              '&.Mui-focusVisible': {
                backgroundColor: '#fff',
                border: '2px solid #000',
              },
            }}
            disableRipple
            aria-label={showGraph ? 'View Details' : 'View Graph'}
          >
            {showGraph ? 'View Details' : 'View Graph'}
          </Button>
        </Typography>
      </Box>
    </MetricCard>
  );
};

export default TimeSeriesCard;
