import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { RoadsideEvent } from '@types';
import React, { useMemo } from 'react';
import { styled, SxProps, Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {
  convertUTCDateToLocalDate,
  gethoursBetweenDates,
} from '../../../../utilities/date-utils';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import RoadsideEventIcon from './RoadSideEventIcon';
import { DefaultToolTip as Tooltip } from 'components/Tooltips';
import OpenIcon from './assets/open.svg';
import ClosedIcon from './assets/closed.svg';
import { RoadsideEventTimeline } from '../RoadsideEventTimeline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined';
import { RoadsideEventStatuses } from 'constants/RoadsideEventStatuses';
import useMediaQuery from '@mui/material/useMediaQuery';

interface RoadsideEventDisplay extends RoadsideEvent {
  jobCreatedAtLocalDisplay: string;
  npsResponseDisplay: string;
  complaintToolTip: string;
  statusDisplay: string;
  ataCalculated: number | string | null;
}
export interface RoadSideEventProps {
  data: RoadsideEvent;
}

const getComplaintToolTip = (externalComplaintResolution: string) => {
  switch (externalComplaintResolution) {
    case 'Open':
      return 'Unresolved complaint';
    case 'Closed':
      return 'Resolved complaint';
    default:
      return '';
  }
};

const getStatus = (status: string, jobCreatedAtLocal: string | Date) => {
  if (
    status === RoadsideEventStatuses.RequestCreated &&
    gethoursBetweenDates(jobCreatedAtLocal) > 24
  ) {
    // job status is RequestCreated and date is greater 24 hours
    return 'Scheduled';
  }
  return status;
};

const RoadsideEventMeta = styled(Typography)(({ theme }) => ({
  alignItems: 'center',
  display: 'inline-flex',
  flexWrap: 'wrap',
  fontSize: '1rem',
  marginRight: theme.spacing(2),
  '& .MuiSvgIcon-root': {
    fontSize: '.875rem',
    marginRight: '.4rem',
  },
}));
const RoadsideEventDetails = styled(Typography)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  fontSize: '1rem',
  marginBottom: theme.spacing(2),
  '& strong': {
    fontWeight: '600',
    marginRight: '2px',
  },
}));
const complaintIconStyle: SxProps<Theme> = {
  position: 'absolute',
  bottom: '0',
  right: '0',
  height: '14px',
  width: '14px',
};
const RoadsideEventComponentRaw = ({
  data: unsafeData,
}: RoadSideEventProps) => {
  const data: RoadsideEventDisplay = useMemo(() => {
    return {
      jobCreatedAtLocalDisplay: unsafeData.jobCreatedAtLocal
        ? convertUTCDateToLocalDate(unsafeData.jobCreatedAtLocal)
        : 'Unavailable',
      npsResponseDisplay:
        unsafeData.npsResponse !== null && unsafeData.npsResponse !== ''
          ? unsafeData.npsResponse.toString()
          : 'Unavailable',
      complaintToolTip: getComplaintToolTip(
        unsafeData.externalComplaintResolution
      ),
      statusDisplay: getStatus(unsafeData.status, unsafeData.jobCreatedAtLocal),
      ataCalculated:
        unsafeData.status === RoadsideEventStatuses.Completed
          ? unsafeData.ata
          : null,
      ...unsafeData,
    };
  }, [unsafeData]);
  const theme = useTheme();
  const isMobile = useMediaQuery((mq: Theme) => mq.breakpoints.down('sm'));

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '10px',
          my: theme.spacing(2),
        }}
      >
        <Box sx={{ width: '56px', mr: theme.spacing(3) }}>
          <RoadsideEventIcon serviceType={data.serviceType} />
        </Box>
        <Box sx={{ flex: isMobile ? 'auto' : '1 1 0' }}>
          <Typography
            component="h4"
            variant="h4"
            sx={{ color: theme.namedColors.utility.black }}
          >
            {data.callerFirstName} {data.callerLastName}
          </Typography>
          <RoadsideEventMeta>
            <LocationOnIcon />
            {data.disablementLocationType}
          </RoadsideEventMeta>
          <RoadsideEventMeta>
            <CalendarTodayIcon />
            {data.jobCreatedAtLocalDisplay}
          </RoadsideEventMeta>
        </Box>
        {data.isExternalComplaint && (
          <Tooltip title={data.complaintToolTip}>
            <Box
              sx={{
                height: '30px',
                width: '30px',
                textAlign: 'center',
                position: 'relative',
              }}
            >
              <AssignmentLateOutlinedIcon
                sx={{ height: '30px', width: '30px' }}
              />
              {data.externalComplaintResolution === 'Open' && (
                <Box
                  component="img"
                  sx={complaintIconStyle}
                  src={OpenIcon}
                ></Box>
              )}
              {data.externalComplaintResolution === 'Closed' && (
                <Box
                  component="img"
                  sx={complaintIconStyle}
                  src={ClosedIcon}
                ></Box>
              )}
            </Box>
          </Tooltip>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '10px',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <RoadsideEventDetails>
          <strong>Policy No:</strong>
          {data.policyNumber}
        </RoadsideEventDetails>
        <RoadsideEventDetails>
          <strong>NPS:</strong>
          {data.npsResponseDisplay}
        </RoadsideEventDetails>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '7px',
          alignItems: 'center',
        }}
      >
        <RoadsideEventDetails>
          <strong>Event:</strong>
          {data.serviceType}
        </RoadsideEventDetails>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '10px',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography component="h5" variant="h5" sx={{ flex: '1 1 0' }}>
          {data.statusDisplay}
        </Typography>
        {data.ataCalculated && data.ataCalculated > 0 && (
          <Typography
            variant="body1"
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <AccessTimeIcon
              sx={{ height: '16px', width: '16px', marginRight: '.25rem' }}
            />{' '}
            ATA: {data.ataCalculated}min
          </Typography>
        )}
        {data.ataCalculated == null && data.eta && data.eta > 0 && (
          <Typography
            variant="body1"
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <AccessTimeIcon
              sx={{ height: '16px', width: '16px', marginRight: '.25rem' }}
            />
            ETA: {data.eta}min
          </Typography>
        )}
      </Box>
      <RoadsideEventTimeline roadsideEvent={data} />
      <Divider />
    </>
  );
};

export const RoadsideEventComponent = React.memo(RoadsideEventComponentRaw);
