import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import useTheme from '@mui/material/styles/useTheme';
import { scaleLinear } from 'd3-scale';
import { arc } from 'd3-shape';
import { FC } from 'react';
import needle from './assets/needle.svg';
import stateneedle from './assets/stateneedle.svg';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme } from '@mui/material/styles';

interface BaseGaugeProps {
  value: number | null;
  stateValue?: number | null;
  min: number;
  max: number;
  label: string | React.ReactElement;
  tooltipContent?: React.ReactFragment | React.ReactChild | React.ReactPortal;
  formatValue?: (value: number) => string;
  enableStateLevelNps: boolean;
}
interface MultiColoredProps extends BaseGaugeProps {
  sectionColors: string[];
  kpiColors: string[];
}
interface SingleColoredProps extends BaseGaugeProps {
  gaugeColor: string;
  kpiColor: string;
}
type GaugeProps = MultiColoredProps | SingleColoredProps;

const createArc = (startAngle: number, endAngle: number) => {
  return arc<void, void>()
    .innerRadius(0.9)
    .outerRadius(1)
    .startAngle(startAngle)
    .endAngle(endAngle)
    .padAngle(0)();
};

const getTickTextPosition = (angle: number) => {
  return arc<void, void>()
    .innerRadius(0.72)
    .outerRadius(0.8)
    .startAngle(angle)
    .endAngle(angle)
    .centroid();
};

const getTicks = (
  min: number,
  max: number,
  sectionCount: number,
  formatValue?: (value: number) => string
) => {
  const ticks: { angleInDegrees: number; text: string; value: number }[] = [];
  const sectionSize = (max - min) / sectionCount;
  let tickIndex = 0;
  while (tickIndex < sectionCount + 1) {
    const value = min + sectionSize * tickIndex;
    ticks.push({
      value,
      text: formatValue ? formatValue(value) : `${value}`,
      angleInDegrees: (180 / sectionCount) * tickIndex,
    });
    tickIndex++;
  }
  return ticks;
};

const displayValue = (
  value: number | null,
  formatValue?: (value: number) => string
): string => {
  if (formatValue && value !== null) {
    return formatValue(value);
  } else if (value !== null) {
    return value.toString();
  }
  return '-';
};

const Gauge: FC<GaugeProps> = (props) => {
  const {
    value,
    min,
    max,
    formatValue,
    label,
    stateValue,
    enableStateLevelNps,
  } = props;

  const isSmallScreen = useMediaQuery((mq: Theme) => mq.breakpoints.down('xs'));

  const theme = useTheme();
  const percentScale = scaleLinear().domain([min, max]).range([0, 1]);
  const angleScale = scaleLinear()
    .domain([0, 1])
    .range([-Math.PI / 2, Math.PI / 2])
    .clamp(true);

  const sectionCount = 4;
  const ticks = getTicks(min, max, sectionCount, formatValue);

  const arcSections = [
    createArc(angleScale(0), angleScale(0.25)),
    createArc(angleScale(0.25), angleScale(0.5)),
    createArc(angleScale(0.5), angleScale(0.75)),
    createArc(angleScale(0.75), angleScale(1)),
  ];
  const percent = percentScale(value || 0);
  const angle = angleScale(percent);

  const statePercent = percentScale(stateValue || 0);
  const stateAngle = angleScale(statePercent);

  const kpiColor =
    'kpiColors' in props
      ? props.kpiColors[Math.min(Math.floor(percent * 4), 3)]
      : props.kpiColor;

  return (
    <Box
      data-testid="gauge"
      sx={{ height: '100%', width: '100%', position: 'relative' }}
    >
      <svg viewBox={[-1, -1, 2.4, 1].join(' ')} style={{ overflow: 'visible' }}>
        {/* guage arcs */}
        <g>
          {arcSections.map((arcSection, i) => {
            if (arcSection) {
              const sectionColor =
                'sectionColors' in props
                  ? props.sectionColors[i]
                  : props.gaugeColor;
              return (
                <path
                  d={arcSection}
                  fill={sectionColor}
                  key={sectionColor + i}
                />
              );
            }
          })}
        </g>

        {/* ticks */}
        {ticks.map((tick, i) => (
          <path
            d="M-0.86,0 H-0.89"
            strokeWidth="0.01"
            stroke="#93989A"
            transform={`rotate(${tick.angleInDegrees})`}
            key={`tick-${i}`}
          />
        ))}

        {/* tick labels */}
        <g>
          {ticks.map((tick, i) => {
            const [x, y] = getTickTextPosition(
              (tick.angleInDegrees - 90) * (Math.PI / 180)
            );
            const isFirstTick = i === 0;
            const isLastTick = i === ticks.length - 1;
            let dx =
              (isLastTick || isFirstTick) && tick.text.length !== 3
                ? 0.02 * (tick.text.length - 3)
                : 0;
            dx = dx * (isLastTick ? -1 : 1);
            return (
              <text
                x={x}
                y={y}
                dx={dx}
                fontSize={0.08}
                letterSpacing={0.003}
                textAnchor="middle"
                fill={theme.namedColors.brand.copyLight}
                alignmentBaseline={
                  isFirstTick || isLastTick ? 'middle' : 'auto'
                }
                key={`tick-label-${i}`}
              >
                {tick.text}
              </text>
            );
          })}
        </g>

        {/* dial */}
        {value !== null && (
          <g
            transform={`rotate(${
              angle * (180 / Math.PI)
            })  translate(-.08, -.95)`}
            data-testid="gauge-needle"
          >
            <svg height="1.5" width=".165" viewBox="0 0 120 1200" fill="grey">
              <image href={needle} height="260" />
            </svg>
          </g>
        )}

        {/* state dial */}
        {enableStateLevelNps && stateValue !== null && (
          <g
            transform={`rotate(${
              stateAngle * (180 / Math.PI)
            })  translate(-.08, -1.19)`}
            data-testid="gauge-state-needle"
          >
            <svg height="1.5" width=".165" viewBox="0 0 120 1200" fill="grey">
              <image href={stateneedle} height="260" width="92" />
            </svg>
          </g>
        )}

        {/* state nps */}
        {enableStateLevelNps && stateValue !== null && (
          <g
            transform={`rotate(${
              stateAngle * (180 / Math.PI)
            })   translate(-.03, -1.23)`}
          >
            <text
              fontSize={0.06}
              letterSpacing={0.003}
              textAnchor="middle"
              fill={theme.namedColors.brand.copyLight}
              key={`state-nps-label`}
              transform={`rotate(${stateAngle * (180 / Math.PI) * -1})`}
            >
              State NPS {stateValue}
            </text>
          </g>
        )}
      </svg>

      {/* value */}
      <Box
        sx={{
          position: 'absolute',
          top: '64%',
          left: '43%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
        }}
      >
        <Typography
          fontSize={isSmallScreen ? '0.6rem' : '2.4rem'}
          lineHeight={1}
          fontWeight={700}
          color={kpiColor}
        >
          {displayValue(value, formatValue)}
        </Typography>
        <Typography
          color={theme.namedColors.brand.copyLight}
          sx={{
            '& p': {
              fontSize: isSmallScreen ? '0.2rem' : '0.75rem',
            },
          }}
        >
          {label}
        </Typography>
      </Box>
    </Box>
  );
};

export default Gauge;
