import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { TimeSeries } from '@types';
import * as d3 from 'd3';
import { useEffect, useRef } from 'react';

export interface TimeSeriesGraphProps {
  data: TimeSeries;
  range: { min: number; max: number };
  color: string;
  pointsColor: string;
  category?: string;
  enableStateLevelNps: boolean;
}

const formatDate = (date: Date) => {
  return date.toLocaleString('default', {
    month: 'short',
    year: '2-digit',
  });
};

const TimeSeriesGraph = (props: TimeSeriesGraphProps) => {
  const svgRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery((mq: Theme) => mq.breakpoints.down('sm'));
  const width = 325;
  const height = 192;
  const margins = {
    top: 40,
    bottom: 20,
    left: 62,
    right: 10,
  };
  const totalWidth = width + margins.left + margins.right;
  const totalHeight = height + margins.top + margins.bottom;

  const lineStrokeWidth = 5;
  const lineStrokeDashArray = 6;

  const currMon = formatDate(new Date());
  const { data, category, color, pointsColor, range } = props;

  useEffect(() => {
    if (!svgRef.current) {
      return;
    }
    const svg = d3.select(svgRef.current);

    // build scales
    const xScale = d3
      .scaleTime()
      .domain([
        d3.min(data, (d) => d.date) || new Date(),
        d3.max(data, (d) => d.date) || new Date(),
      ])
      .range([0, width]);
    const yScale = d3
      .scaleLinear()
      .domain([range.min, range.max])
      .range([height, 0]);
    // build axes
    const xAxisModel = d3
      .axisBottom(xScale)
      .tickValues(data.map((d) => d.date))
      .tickFormat((date) =>
        (date as Date).toLocaleString('default', { month: 'short' })
      );
    const yTickStep = (range.max - range.min) / 4;
    const yAxisModel = d3
      .axisLeft(yScale)
      .tickValues(d3.range(range.min, range.max + yTickStep, yTickStep))
      .tickFormat((value) => {
        if (category === 'ATA') {
          return value == 1.2
            ? `${(Number(value) * 100).toFixed()}+ min`
            : `${(Number(value) * 100).toFixed()} min`;
        }
        if (category === 'NPS') {
          return `${(Number(value) * 100).toFixed()}`;
        }
        return `${(Number(value) * 100).toFixed()}%`;
      });

    // draw axes
    svg
      .append('g')
      .attr('class', 'axis x-axis')
      .attr('transform', `translate(${0}, ${height})`)
      .call(xAxisModel);
    svg.append('g').attr('class', 'axis').call(yAxisModel);
    // style axes and labels
    svg
      .selectAll('.axis path,line')
      .attr('stroke', theme.namedColors.dark[100]);
    svg
      .selectAll('.axis text')
      .attr('fill', theme.namedColors.dark[500])
      .attr('font-size', '0.8rem');
    svg
      .selectAll('.x-axis .tick:first-of-type text')
      .attr('text-anchor', 'start');
    svg.selectAll('.x-axis .tick:last-of-type text').attr('text-anchor', 'end');
    // Draw policy line
    svg
      .append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', color)
      .attr('stroke-width', lineStrokeWidth)
      .attr(
        'd',
        d3
          .line<typeof data[number]>()
          .x((d) => xScale(d.date))
          .y((d) => yScale(d.policyHolders || 0))
      );
    // draw state line
    if (props.enableStateLevelNps) {
      svg
        .append('path')
        .datum(data)
        .attr('fill', 'none')
        .attr('stroke', color)
        .attr('stroke-width', lineStrokeWidth)
        .style('stroke-dasharray', lineStrokeDashArray)
        .attr(
          'd',
          d3
            .line<typeof data[number]>()
            .x((d) => xScale(d.date))
            .y((d) => yScale(d.state || 0))
        );
    }

    //Create tooltip
    const Tooltip = d3
      .select('body')
      .append('div')
      .attr('class', 'tooltip')
      .attr('role', 'tooltip')
      .attr('id', 'timegraphtooltip')
      .style('opacity', 0)
      .style('position', 'fixed')
      .style('pointer-events', 'none')
      .style('color', '#403C3C')
      .style('background-color', '#fff')
      .style('border', '1px solid #5e5858')
      .style('border-radius', '6px')
      .style('padding', '5px 8px 3px 8px');
    // Draw the points
    svg
      .append('g')
      .selectAll('dot')
      .data(data)
      .enter()
      .append('circle')
      .attr('aria-describedby', 'timegraphtooltip')
      .attr('cx', function (d) {
        return xScale(d.date);
      })
      .attr('cy', function (d) {
        return yScale(d.policyHolders || 0);
      })
      .attr('r', 5)
      .attr('fill', pointsColor || '#00000')
      .attr('data-testid', 'time-graph')
      .on('focus', function (d, data) {
        const pos = document.activeElement?.getBoundingClientRect();
        if (pos) {
          d3.select(this).attr('fill', '#0097FF');
          const month = formatDate(data.date as Date);
          Tooltip.style('opacity', 1)
            .text(month + ' - ' + data.count + data.suffix)
            .style('top', `${pos?.top + window.scrollX - 50}px`);
          if (month == currMon) {
            Tooltip.style('left', `${pos.left + window.scrollX - 137}px`);
          } else {
            Tooltip.style('left', `${pos.left + window.scrollX - 70}px`);
          }
        }
      })
      .on('mouseover', function (d, data) {
        const month = formatDate(data.date as Date);
        Tooltip.style('opacity', 1)
          .text(month + ' - ' + data.count + data.suffix)
          .style('top', `${d.pageY - 50}px`);
        if (month == currMon) {
          Tooltip.style('left', `${d.pageX - 137}px`).style('right', null);
        } else {
          Tooltip.style('left', `${d.pageX - 70}px`).style('right', null);
        }
      })
      .on('mouseout focusout', function () {
        Tooltip.style('opacity', 0);
        d3.select(this).attr('fill', '#000');
      });
    return () => {
      svg.selectAll('*').remove();
      d3.selectAll('.tooltip').remove();
    };
  }, [data, color, theme]);
  return (
    <Box
      sx={{
        minWidth: isMobile ? '100%' : totalWidth,
      }}
    >
      <svg viewBox={[0, 0, totalWidth, totalHeight].join(', ')}>
        {/* legend */}
        <g transform={`translate(0, 15)`}>
          <g>
            <line x1="0" x2="32" stroke={color} strokeWidth={lineStrokeWidth} />
            <text
              x="40"
              fill={theme.namedColors.dark[500]}
              fontSize="0.8rem"
              alignmentBaseline="central"
            >
              Your Policyholders
            </text>
          </g>
          {props.enableStateLevelNps && (
            <g transform={`translate(160, 0)`}>
              <line
                x1="0"
                x2="32"
                stroke={color}
                strokeWidth={lineStrokeWidth}
                strokeDasharray={lineStrokeDashArray}
              />
              <text
                x="40"
                fontSize="0.8rem"
                fill={theme.namedColors.dark[500]}
                alignmentBaseline="central"
              >
                Your State
              </text>
            </g>
          )}
        </g>
        {/* graph */}
        <g
          ref={svgRef}
          transform={`translate(${margins.left}, ${margins.top})`}
        />
      </svg>
    </Box>
  );
};
export default TimeSeriesGraph;
