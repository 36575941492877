import React from 'react';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { useTheme, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import useMediaQuery from '@mui/material/useMediaQuery';

interface MetricType {
  count: number;
  title: string;
  type: string;
}

type PerformanceMetricsOuterLayerProps = React.PropsWithChildren<{
  loading: boolean;
  metricType?: MetricType | undefined;
  title?: string;
  isMobile?: boolean;
}>;

const TitleBar = (props: {
  theme: Theme;
  title: string;
  metricType?: MetricType | undefined;
  isMobile?: boolean;
}) => {
  const theme = useTheme();
  const fetchLabel = (val: MetricType) => {
    let labelColor = '';
    let labelText = '';
    let value: number | string = 0;
    if (val.type === 'NPS') {
      labelColor =
        val.count > 1
          ? theme.namedColors.utility.success
          : theme.namedColors.utility.highlight;
      labelText =
        val.count <= 1
          ? 'Needs Improvement'
          : val.count > 50
          ? 'Great'
          : 'Good';
      value = val.count;
    } else if (val.type === 'ATA') {
      labelColor =
        val.count < 90
          ? theme.namedColors.utility.success
          : theme.namedColors.utility.highlight;
      labelText =
        val.count > 90
          ? 'Needs Improvement'
          : val.count < 60
          ? 'Great'
          : 'Good';
      value = `${val.count} min`;
    } else {
      labelColor =
        val.count > 30
          ? theme.namedColors.utility.success
          : theme.namedColors.utility.highlight;
      labelText =
        val.count < 30
          ? 'Low Digital App Request'
          : val.count > 50
          ? 'Great Digital App Request'
          : 'Good Digital App Request';
      value = `${val.count}%`;
    }
    return (
      <Typography
        style={{ color: labelColor, fontSize: '1.19rem', fontWeight: '600' }}
        component="h3"
      >
        <span
          style={{
            backgroundColor: labelColor,
            color: 'white',
            borderRadius: '100px',
            padding: '5px 7px',
          }}
        >
          {value}
        </span>
        {`  ${labelText}`}
      </Typography>
    );
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: props.isMobile ? '8px' : '12px 16px',
          width: '100%',
          border: '1px solid #DADCE0',
          borderRadius: '16px 16px 0px 0px',
          borderBottom: '0px',
          backgroundColor: props.theme.namedColors.utility.white,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          {props.metricType ? (
            <>
              <Typography
                style={{ fontSize: '1.25rem', fontWeight: '600' }}
                component="h3"
              >
                {props.metricType.title}:
              </Typography>
              &nbsp;
              {fetchLabel(props.metricType)}
            </>
          ) : (
            <Typography
              style={{ fontSize: '1.25rem', fontWeight: '600' }}
              component="h3"
            >
              {props.title}
            </Typography>
          )}
        </Box>
      </Box>
      <Divider
        sx={{
          mx: props.isMobile ? theme.spacing(1) : theme.spacing(2),
          mt: '-1px',
        }}
      />
    </>
  );
};

const BodyContent = (props: {
  theme: Theme;
  loading: boolean;
  children: React.ReactNode;
  isMobile: boolean;
}) => {
  return (
    <Box
      sx={{
        flexWrap: 'wrap',
        justifyContent: props.loading ? 'space-around' : 'inherit',
        width: '100%',
        padding: props.isMobile ? '8px' : '24px 16px',
        backgroundColor: props.theme.namedColors.utility.white,
        border: '1px solid #DADCE0',
        borderRadius: '0px 0px 16px 16px',
        borderTop: '0px',
        display: 'flex',
        flexDirection: props.isMobile ? 'column' : 'row',
        gap: '10px',
      }}
    >
      {props.loading ? (
        <CircularProgress
          data-testid="circular-progress"
          size="75px"
          sx={{ margin: '1.25rem' }}
        />
      ) : (
        React.Children.map(props.children, (child) => <>{child}</>)
      )}
    </Box>
  );
};

const PerformanceMetricsOuterLayer = (
  props: PerformanceMetricsOuterLayerProps
) => {
  const theme = useTheme();
  const isMobile = useMediaQuery((mq: Theme) => mq.breakpoints.down('sm'));

  return (
    <Box>
      <TitleBar
        theme={theme}
        title={props.title ? props.title : 'Performance Metrics'}
        metricType={props.metricType}
        isMobile={isMobile}
      />
      <BodyContent
        theme={theme}
        loading={props.loading}
        children={props.children}
        isMobile={isMobile}
      />
    </Box>
  );
};

export default PerformanceMetricsOuterLayer;
