import { SxProps, Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ReactNode } from 'react';

interface TitleProps {
  sx?: SxProps<Theme>;
  children?: ReactNode;
}
/**
 * Generic Title component for use on the main dashboard cards.
 *
 * @param {TitleProps} { children, sx }
 * @return {*}
 */
const Title = ({ children, sx }: TitleProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery((mq: Theme) => mq.breakpoints.down('md'));

  return (
    <Typography
      sx={{ p: isSmallScreen ? theme.spacing(1) : theme.spacing(2), ...sx }}
      component="h3"
      variant="h3"
    >
      {children}
    </Typography>
  );
};
export default Title;
