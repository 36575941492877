import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { useAuthentication } from 'hooks/useAuthentication';
import { useDefaultAgentId } from 'hooks/useDefaultAgentId';
import { ClientSettings, SettingData } from '@types';
import SettingItem from './components/SettingItem';
import { Toast } from 'components/Snackbar';
import { useSaveClientSetting } from 'api/client-settings';
import { useSettings } from 'hooks/useSettings';

/**
 *Setting View
 *
 * @return {*}
 */
const Settings = ({
  clientConfig,
}: {
  clientConfig: ClientSettings | null;
}) => {
  const { auth } = useAuthentication();
  const { clientSettings, setClientSettings } = useSettings();
  const { sendGAEvent } = useGoogleAnalytics();
  const { defaultAgentId } = useDefaultAgentId();
  const [settingsPatchData, setSettingsPatchData] =
    useState<SettingData | null>(null);
  const [showToast, setShowToast] = useState<boolean>(false);
  const id = auth.hasAllReadAccess() ? auth.getName() : defaultAgentId;
  const editAccess =
    auth.isSystemAdmin() || auth.isClientAdmin() ? true : false;
  const clientCode = clientConfig?.clientCode || '';
  const {
    data: updatedSetting,
    refetch: refetchSaveSettings,
    status: saveSettingsStatus,
  } = useSaveClientSetting(settingsPatchData, clientCode, id);
  document.title = 'Settings | Roadside AID';
  useEffect(() => {
    if (settingsPatchData) {
      refetchSaveSettings();
      setShowToast(true);
    }
  }, [settingsPatchData]);

  useEffect(() => {
    if (updatedSetting) {
      const settings = clientSettings;
      settings.forEach((item: ClientSettings) => {
        if (item.clientCode === updatedSetting.clientCode) {
          if (auth.isSystemAdmin() && updatedSetting.settings) {
            updatedSetting.settings.forEach((item: SettingData) => {
              item['enabled'] = item.feature.isPremium
                ? item.feature.systemAdminEnabled === true
                : item.enabled;
            });
            item.settings = updatedSetting.settings;
          } else if (auth.isClientAdmin() && updatedSetting.settings) {
            const settingsFiltered = updatedSetting.settings.filter(
              (item: SettingData) => {
                return item.feature.isPremium
                  ? item.feature.systemAdminEnabled == true
                  : true;
              }
            );
            item.settings = settingsFiltered;
          }
        }
      });
      setClientSettings(settings);
    }
  }, [updatedSetting]);

  const onSaveSetting = (data: SettingData) => {
    setShowToast(false);
    if (auth.isSystemAdmin() || auth.isClientAdmin()) {
      if (data.feature.isPremium && auth.isSystemAdmin())
        data.feature.systemAdminEnabled = data.enabled;

      setSettingsPatchData(data);
    }
  };

  return (
    <Box data-testid="settings-container">
      <Toast
        open={
          showToast &&
          (saveSettingsStatus === 'success' || saveSettingsStatus === 'error')
        }
        message={`${
          saveSettingsStatus === 'success'
            ? 'Your changes were saved succesfully'
            : 'Some error occured. Please try again later'
        }`}
        type={`${saveSettingsStatus === 'success' ? 'INFO' : 'ERROR'}`}
      />
      <Box>
        {!clientConfig && (
          <CircularProgress
            size="50px"
            sx={{
              margin: '0 auto',
              position: 'fixed',
              top: '50%',
              left: '50%',
              zIndex: '9999',
            }}
          />
        )}
        <Box>
          {clientConfig &&
            clientConfig.settings.map((item: SettingData) => {
              return (
                <SettingItem
                  key={`setting-${item.settingName}`}
                  data={item}
                  onSaveSetting={onSaveSetting}
                  sendEventToGA={sendGAEvent}
                  editAccess={editAccess}
                />
              );
            })}
        </Box>
      </Box>
    </Box>
  );
};

export default Settings;
