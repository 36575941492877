import { FC, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { styled, Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import { Sidebar } from '../components/Sidebar';
import { RouterLink } from '../components/Routes/RouterLink';
import { Navbar } from 'layouts/components/Navbar';
import CustomThemeProvider from 'theme/CustomThemeProvider';
import useMediaQuery from '@mui/material/useMediaQuery';

const Drawer = styled(MuiDrawer)(() => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
  },
}));

const LayoutMain: FC = () => {
  const SidebarLinks = RouterLink('main');
  const isMobile = useMediaQuery((mq: Theme) => mq.breakpoints.down('sm'));

  return (
    <CustomThemeProvider themePrimary={SidebarLinks.clientTheme}>
      <Box sx={{ display: 'flex' }} data-testid="main-layout-container">
        <Navbar {...SidebarLinks} />
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Drawer
            variant="permanent"
            sx={{
              paddingTop: '70px',
              scrollPaddingTop: '70px',
              width: isMobile ? '110px' : '130px',
            }}
          >
            <Sidebar {...SidebarLinks} />
          </Drawer>
          <Box
            component="main"
            id="agero-scroll-container"
            sx={{
              flexGrow: 1,
              height: isMobile ? 'auto' : '100vh',
              overflow: 'auto',
              paddingTop: '70px',
              scrollPaddingTop: '70px',
            }}
          >
            <Suspense fallback={null}>
              <Outlet />
            </Suspense>
          </Box>
        </Box>
      </Box>
    </CustomThemeProvider>
  );
};

export default LayoutMain;
