import useTheme from '@mui/material/styles/useTheme';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { getNPSDetails } from '../../utilities/GetNPSDetails';

const CollapsedNpsMetric = ({ nps }: { nps: number | null }) => {
  const theme = useTheme();

  const { text, performanceText, color } = getNPSDetails(nps, theme);

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      <Box>
        <Typography
          color={theme.namedColors.brand.copyLight}
          variant="subtitle2"
          sx={{ lineHeight: '1rem' }}
        >
          {text}
        </Typography>
        <Typography color={color} variant="subtitle2">
          <strong>{performanceText}</strong>
        </Typography>
      </Box>
      <Typography color={color} sx={{ fontSize: '2.75rem' }}>
        <strong>{nps}</strong>
      </Typography>
    </Box>
  );
};

export default CollapsedNpsMetric;
