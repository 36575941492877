import React, { useEffect, useMemo } from 'react';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { RoadsideEvent, SMSPopupData } from '@types';
import { Title } from 'components/Title';
import { styled, Theme, useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import ToggleButton from '@mui/material/ToggleButton';
import { compare } from '../../../../utilities/compare';

import SurveyFeedbackItem from '../SurveyFeedbackItem';
import {
  GoogleAnalyticsEventType,
  GoogleAnalyticsEventOptions,
} from 'constants/GoogleAnalytics';
export interface SurveyFeedbackEventsProps {
  data: RoadsideEvent[];
  isLoading: boolean;
  isError: boolean;
  enableWinback: boolean;
  enableGoogleReviewRequest: boolean;
  sendEventToGA: (
    event_type: GoogleAnalyticsEventType,
    data: GoogleAnalyticsEventOptions
  ) => void;
  enableBetaNotification: boolean;
  showGoogleReviewRequest: (data: SMSPopupData) => void;
  sendRequestFor: number;
}

const NoDataBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(2),
}));

const SurveyFeedback = ({
  data: unsafeData,
  isLoading,
  isError,
  enableWinback,
  sendEventToGA,
  enableBetaNotification,
  showGoogleReviewRequest,
  sendRequestFor,
  enableGoogleReviewRequest,
}: SurveyFeedbackEventsProps) => {
  const data = useMemo(
    () => [
      ...unsafeData
        .filter(
          (x) =>
            (x.npsResponse !== undefined && x.npsResponse !== null) ||
            (x.surveyComments !== undefined &&
              x.surveyComments !== null &&
              x.surveyComments.trim() !== '')
        )
        .sort((a, b) =>
          compare(a.jobCreatedAtLocal, b.jobCreatedAtLocal, false)
        ),
    ],
    [unsafeData]
  );
  const isSmallScreen = useMediaQuery((mq: Theme) => mq.breakpoints.down('md'));
  const theme = useTheme();

  const [filteredData, setFilteredData] = React.useState(data);
  const [isFilterSurvey, setIsFilterSurvey] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  let firstCaseOfGR = 0;
  useEffect(() => {
    setIsFilterSurvey(false);
    setFilteredData(data);
    setLoading(isLoading);
  }, [data]);

  const filterSurveyComments = () => {
    setLoading(true);
    if (!isFilterSurvey) {
      const filtervalues = data.filter(
        (x) =>
          x.surveyComments !== undefined &&
          x.surveyComments !== null &&
          x.surveyComments.trim() !== ''
      );
      setFilteredData(filtervalues);
      sendEventToGA(GoogleAnalyticsEventType.SelectContent, {
        content_type: 'Survey Feedback',
        item_id: 'Show Only Responses with Comments',
      });
    } else {
      setFilteredData(data);
    }
    setIsFilterSurvey(!isFilterSurvey);
    setLoading(false);
  };

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: data.length > 0 && !isSmallScreen ? '100%' : 'auto',
        overflow: 'hidden',
        wordBreak: 'break-word',
      }}
    >
      <Title>
        Survey Feedback
        <ToggleButton
          data-testid="comment-filter"
          value="comments"
          selected={isFilterSurvey}
          onClick={filterSurveyComments}
          sx={{
            float: 'right',
            height: 'auto',
            lineHeight: 'inherit',
          }}
        >
          Show Only Responses with Comments
        </ToggleButton>
      </Title>
      <Divider
        sx={{
          mr: theme.spacing(2),
          ml: theme.spacing(2),
        }}
      />
      {!loading && !isError && filteredData.length === 0 ? (
        <NoDataBox data-testid="empty-container">
          <Typography component="h3" variant="h6">
            No Feedback Found
          </Typography>
        </NoDataBox>
      ) : (
        <>
          {isLoading ? (
            <NoDataBox data-testid="loading-container">
              <Typography gutterBottom>Loading Events</Typography>
              <CircularProgress size="50px" />
            </NoDataBox>
          ) : (
            <>
              {isError ? (
                <NoDataBox data-testid="error-container">
                  <Typography component="h3" variant="h6">
                    Error Loading Feedback
                  </Typography>
                </NoDataBox>
              ) : (
                <Box
                  sx={{
                    flex: '1 1 0',
                    maxHeight: '100%',
                    minHeight: !isSmallScreen ? '500px' : 'auto',
                    overflowY: !isSmallScreen ? 'auto' : 'visible',
                    margin: '2px', // Offsets the scroll bar
                    px: theme.spacing(2),
                  }}
                  data-testid="data-container"
                >
                  <Box>
                    {filteredData.map((dataItem) => {
                      if (
                        dataItem.googleReviewRequestStatus ==
                          'ReviewNotInitiated' &&
                        enableBetaNotification &&
                        firstCaseOfGR == 0
                      ) {
                        firstCaseOfGR++;
                        dataItem['showBetaNotification'] = true;
                      }
                      return (
                        <SurveyFeedbackItem
                          key={dataItem.jobId}
                          data={dataItem}
                          enableWinback={enableWinback}
                          enableGoogleReviewRequest={enableGoogleReviewRequest}
                          showGoogleReviewRequest={showGoogleReviewRequest}
                          sendRequestFor={sendRequestFor}
                        />
                      );
                    })}
                  </Box>
                </Box>
              )}
            </>
          )}
        </>
      )}
    </Paper>
  );
};

export default SurveyFeedback;
