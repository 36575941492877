import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import HubspotContactForm from 'components/HubspotContactForm/HubspotContactForm';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`contact-tabpanel-${index}`}
      aria-labelledby={`contact-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'div'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `contact-tab-${index}`,
    'aria-controls': `contact-tab-${index}`,
    'data-testid': `contact-tab-${index}`,
    sx: {
      opacity: 1,
      textTransform: 'none',
      borderBottom: '2px solid #fff',
      '&: hover': {
        borderBottom: '2px solid #d62311',
      },
      '&& .MuiTouchRipple-rippleVisible': {
        opacity: 0.5,
      },
    },
  };
}

const Contact = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }} data-testid="contact-conatiner">
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          role="tablist"
          aria-label="Contact Tabs"
          variant="fullWidth"
          textColor="inherit"
          data-testid="contactTab"
        >
          <Tab label="Send Us a Message" {...a11yProps(0)} tabIndex={0} />
          <Tab label="Request a Call Back" {...a11yProps(1)} tabIndex={0} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0} data-testid="email-tab-content">
        <HubspotContactForm
          region="na1"
          portalId={`${process.env.REACT_APP_HUBSPOT_PORTAL_ID || '2932510'}`}
          formId={'32913bec-c0bd-4da5-9bdc-ef4c59098179'}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1} data-testid="call-tab-content">
        <HubspotContactForm
          region="na1"
          portalId={`${process.env.REACT_APP_HUBSPOT_PORTAL_ID || '2932510'}`}
          formId={'348a0a22-3751-407b-a600-9684041bced2'}
        />
      </CustomTabPanel>
    </Box>
  );
};

export default Contact;
